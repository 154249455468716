import React from "react";
import theme from "theme";
import { Theme, Text, Button, Box, Section, Image, LinkBox } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Home | Welcome to Jeollanamdo!
			</title>
			<meta name={"description"} content={"Precision in Every Shot"} />
			<meta property={"og:title"} content={"Home | Welcome to Jeollanamdo!"} />
			<meta property={"og:description"} content={"Precision in Every Shot"} />
			<meta property={"og:image"} content={"https://biz.silentlakers.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://biz.silentlakers.com/img/icon.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://biz.silentlakers.com/img/icon.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://biz.silentlakers.com/img/icon.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://biz.silentlakers.com/img/icon.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://biz.silentlakers.com/img/icon.png"} />
			<meta name={"msapplication-TileImage"} content={"https://biz.silentlakers.com/img/icon.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="160px 0 170px 0" background="linear-gradient(180deg,rgba(0, 0, 0, 0.43) 0%,rgba(0, 0, 0, 0.43) 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://biz.silentlakers.com/img/1.jpg) 0% 0% /cover repeat scroll padding-box" md-padding="80px 0 90px 0" quarkly-title="Hero-8">
			<Box
				min-width="100px"
				min-height="100px"
				lg-width="100%"
				display="flex"
				flex-direction="column"
				align-items="center"
			>
				<Text
					margin="0px 0px 20px 0px"
					font="normal 700 64px/1.2 --fontFamily-sansTrebuchet"
					sm-font="normal 700 42px/1.2 --fontFamily-sans"
					padding="0px 200px 0px 200px"
					text-align="center"
					color="--light"
					lg-padding="0px 0 0px 0"
				>
					Precision in Every Shot
					<br />
					Jeollanamdo
				</Text>
				<Text
					margin="0px 0px 50px 0px"
					font="--lead"
					color="#d4dae0"
					padding="0px 100px 0px 100px"
					text-align="center"
					lg-padding="0px 0 0px 0"
				>
					Welcome to Jeollanamdo, where every visit hits the mark! Whether you're a seasoned marksman or new to the sport, our state-of-the-art facility offers an exceptional shooting experience in a safe and professional environment. Dive into the world of precision shooting and find your aim with us.
				</Text>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					sm-flex-direction="column"
					sm-align-self="stretch"
				>
					<Button
						margin="0px 15px 0px 0px"
						padding="12px 28px 12px 28px"
						background="#3f24d8"
						border-radius="8px"
						font="normal 400 17px/1.5 --fontFamily-sans"
						sm-margin="0px 0 15px 0px"
						transition="background-color 0.2s ease 0s"
						border-width="2px"
						border-style="#3f24d8"
						border-color="#3f24d8"
						hover-background="rgba(63, 36, 216, 0)"
						href="/contacts"
						type="link"
						text-decoration-line="initial"
						align-self="center"
					>
						Contact Us
					</Button>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" lg-padding="56px 0 56px 0" sm-padding="32px 0 32px 0" background="--color-light">
			<Box
				margin="0px 0px 32px 0px"
				md-margin="0px 0px 40px 0px"
				lg-margin="0px 0px 33px 0px"
				display="flex"
				flex-direction="column"
				align-items="center"
				lg-padding="15px 15px 15px 15px"
				sm-margin="0px 0px 10px 0px"
				justify-content="center"
			>
				<Text
					font="--lead"
					color="--darkL2"
					text-align="center"
					md-width="100%"
				>
					At Jeollanamdo, we provide a top-tier shooting range designed for enthusiasts and professionals alike. Our facility is equipped with the latest in shooting technology and maintained by experienced professionals dedicated to upholding the highest safety standards. Our diverse range configurations cater to all levels of experience, making every session at Jeollanamdo both challenging and rewarding.
				</Text>
			</Box>
			<LinkBox
				display="flex"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-wrap="wrap"
				width="100%"
				margin="0px 0px 16px 0px"
				justify-content="flex-start"
				md-margin="0px 0px 16px 0px"
			>
				<Box
					width="100%"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					md-width="100%"
					lg-width="100%"
				>
					<Image
						src="https://biz.silentlakers.com/img/2.jpg"
						border-radius="24px"
						max-width="100%"
						max-height="522px"
						width="100%"
						object-fit="cover"
						lg-max-height="392px"
						object-position="0% 30%"
						sm-max-height="213px"
					/>
				</Box>
				<Box
					width="100%"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-border-width="0px"
					flex-direction="column"
					padding="16px 12px 16px 12px"
				>
					<Text font="--headline3" color="--dark" margin="0px 0px 8px 0px">
					Advanced Safety Features
					</Text>
					<Text margin="0px 0px 0px 0px" font="--lead" color="--grey">
					Our range is designed with your safety in mind, featuring automated target systems and professional supervision.
					</Text>
				</Box>
			</LinkBox>
			<LinkBox
				display="flex"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-wrap="wrap"
				width="100%"
				margin="0px 0px 16px 0px"
				justify-content="flex-start"
				md-margin="0px 0px 16px 0px"
			>
				<Box
					width="100%"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					md-width="100%"
					lg-width="100%"
				>
					<Image
						src="https://biz.silentlakers.com/img/3.jpg"
						border-radius="24px"
						max-width="100%"
						max-height="522px"
						width="100%"
						object-fit="cover"
						lg-max-height="392px"
						object-position="0% 30%"
						sm-max-height="213px"
					/>
				</Box>
				<Box
					width="100%"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-border-width="0px"
					flex-direction="column"
					padding="16px 12px 16px 12px"
				>
					<Text font="--headline3" color="--dark" margin="0px 0px 8px 0px">
					Family-Friendly Environment
					</Text>
					<Text margin="0px 0px 0px 0px" font="--lead" color="--grey">
					We offer a welcoming atmosphere that respects the sport and its traditions, perfect for family outings.
					</Text>
				</Box>
			</LinkBox>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0" background="linear-gradient(180deg,rgba(5, 7, 33, 0.9) 0%,rgba(5, 7, 33, 0.8) 100%) 0 0 no-repeat">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				width="50%"
				display="flex"
				flex-direction="column"
				justify-content="space-between"
				md-width="100%"
				padding="0px 80px 0px 0px"
				lg-padding="0px 30px 0px 0px"
				md-padding="0px 0px 0px 0px"
			>
				<Text margin="0px 0px 0px 0px" color="--light" font="--headline2" md-margin="0px 0px 30px 0px">
				Shoot for Success at Jeollanamdo!
				</Text>
			</Box>
			<Box
				width="50%"
				display="flex"
				flex-direction="column"
				justify-content="space-between"
				md-width="100%"
			>
				<Text margin="0px 0px 0px 0px" color="#F7FBFF" font="--base">
				Book Your Spot Today and Experience Precision Like Never Before!
				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});